// Grid settings
$enable-flex:           true;
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Base colors for the site

$white          : #fff;
$gainsboro      : #EBEDEE;
$lightgray      : #D3D3D3;
$silver         : #C0C0C0;
$darkgray       : #A9A9A9;
$gray           : #808080;
$dimgray        : #696969;
$black          : #000000;

// Special colors for the site

$green			: #008833;
$lightgreen 	: #09A945;
$mediumgreen	: #006F29;
$darkgreen		: #00501E;
