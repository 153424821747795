// Header

@mixin heading {
    h2, h3, h4, h5, h6, p, b {
        @content; } }

@mixin font-size($sizeValue: 1) {
  font-size: $sizeValue * 16 * 1px;
  font-size: $sizeValue * 1rem; }

@mixin center-block {
  display: block;
  margin-left: auto;
  margin-right: auto; }
@mixin placeholder {
  &::-webkit-input-placeholder {
    @content; }
  &:-moz-placeholder {
    @content; }
  &::-moz-placeholder {
    @content; }
  &:-ms-input-placeholder {
    @content; } }

@mixin clearfix {
  content: "";
  display: table; }

@mixin clearfix-after {
  clear: both; }

%clearfix {
  &:before, &:after {
    content: "";
    display: table; }
  &:after {
    clear: both; } }

//Boton fantasma
@mixin btnGhost {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  text-indent: 200%;
  white-space: nowrap;
  font-size: 0;
  opacity: 0; }

@mixin hoverActiveFocus {
  &:hover, &:active, &:focus {
    @content; } }

@mixin screen-reader-text {
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important; }

@mixin backface-visibility($style: visible) {
  @each $prefix in -moz, -ms, -o, -webkit {
    #{$prefix}-backface-visibility: $style; }
  backface-visibility: $style; }

@mixin message($glyph: "\f05a", $color: $info) {
  padding: 1em 1.618em 1em 3.631em;
  background: rgba($color, 0.7);
  margin-bottom: 2.618em;
  font-weight: normal;
  position: relative;
  color: #fff;
  text-shadow: none;
  &:before {
    font-family: 'FontAwesome';
    content: $glyph;
    color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 2.618em;
    background: rgba(darken($color, 3%), 0.7);
    text-align: center;
    padding: 1em .618em;
    font-weight: normal !important;
    text-shadow: none; }
  a {
    color: #fff;
    text-decoration: underline;
    &:hover {
      color: #fff;
      text-decoration: none; } }
  a.button {
    background: #fff;
    color: $color;
    font-size: .857em;
    padding: .202em .618em;
    &:hover,
    &:active {
      background: rgba(white, 0.8);
      color: $color; } } }

@mixin transition($transition...) {
  -moz-transition: $transition;
  -o-transition: $transition;
  -webkit-transition: $transition;
  transition: $transition; }

@mixin transition-property($property...) {
  -moz-transition-property: $property;
  -o-transition-property: $property;
  -webkit-transition-property: $property;
  transition-property: $property; }

@mixin transition-duration($duration...) {
  -moz-transition-duration: $duration;
  -o-transition-duration: $duration;
  -webkit-transition-duration: $duration;
  transition-duration: $duration; }

@mixin transition-timing-function($timing...) {
  -moz-transition-timing-function: $timing;
  -o-transition-timing-function: $timing;
  -webkit-transition-timing-function: $timing;
  transition-timing-function: $timing; }

@mixin transition-delay($delay...) {
  -moz-transition-delay: $delay;
  -o-transition-delay: $delay;
  -webkit-transition-delay: $delay;
  transition-delay: $delay; }

@mixin transform($transforms) {
  -moz-transform: $transforms;
  -o-transform: $transforms;
  -ms-transform: $transforms;
  -webkit-transform: $transforms;
  transform: $transforms; }

@mixin rotate($deg) {
  @include transform(rotate(#{$deg}deg)); }

@mixin scale($scale) {
  @include transform(scale($scale)); }

@mixin translate($x, $y) {
  @include transform(translate($x, $y)); }

@mixin translateY($y) {
  @include transform(translateY($y)); }

@mixin translateX($x) {
  @include transform(translateX($x)); }

@mixin skew($x, $y) {
  @include transform(skew(#{$x}deg, #{$y}deg)); }

@mixin transform-origin($origin) {
  moz-transform-origin: $origin;
  -o-transform-origin: $origin;
  -ms-transform-origin: $origin;
  -webkit-transform-origin: $origin;
  transform-origin: $origin; }

@mixin keyframes($name) {
  @-o-keyframes #{$name} {
    @content; }

  @-moz-keyframes #{$name} {
    @content; }

  @-webkit-keyframes #{$name} {
    @content; }

  @-keyframes #{$name} {
    @content; } }

@mixin animation-name($name...) {
  -o-animation-name: $name;
  -moz-animation-name: $name;
  -webkit-animation-name: $name;
  animation-name: $name; }

@mixin animation-duration($duration...) {
  -o-animation-duration: $duration;
  -moz-animation-duration: $duration;
  -webkit-animation-duration: $duration;
  animation-duration: $duration; }

@mixin animation-duration($duration...) {
  -o-animation-duration: $duration;
  -moz-animation-duration: $duration;
  -webkit-animation-duration: $duration;
  animation-duration: $duration; }

@mixin animation-timing-function($timing...) {
  -o-animation-timing-function: $timing;
  -moz-animation-timing-function: $timing;
  -webkit-animation-timing-function: $timing;
  animation-timing-function: $timing; }

@mixin animation-iteration-count($count...) {
  -o-animation-iteration-count: $count;
  -moz-animation-iteration-count: $count;
  -webkit-animation-iteration-count: $count;
  animation-iteration-count: $count; }

@mixin animation-direction($direction...) {
  -o-animation-direction: $direction;
  -moz-animation-direction: $direction;
  -webkit-animation-direction: $direction;
  animation-direction: $direction; }

@mixin animation-fill-mode($fill...) {
  -o-animation-fill-mode: $fill;
  -moz-animation-fill-mode: $fill;
  -webkit-animation-fill-mode: $fill;
  animation-fill-mode: $fill; }

@mixin animation-play-state($state...) {
  -o-animation-play-state: $state;
  -moz-animation-play-state: $state;
  -webkit-animation-play-state: $state;
  animation-play-state: $state; }


@mixin animation-play-stat($stat...) {
  -o-animation-play-stat: $stat;
  -moz-animation-play-stat: $stat;
  -webkit-animation-play-stat: $stat;
  animation-play-stat: $stat; }

@mixin animation-name($name...) {
  -o-animation-name: $name;
  -moz-animation-name: $name;
  -webkit-animation-name: $name;
  animation-name: $name; }


@mixin animation($animation...) {
  -o-animation: $animation;
  -moz-animation: $animation;
  -webkit-animation: $animation;
  animation: $animation; }

// Filter

@mixin filter($filter-type,$filter-amount) {
  -webkit-filter: $filter-type+unquote('(#{$filter-amount})');
  -moz-filter: $filter-type+unquote('(#{$filter-amount})');
  -ms-filter: $filter-type+unquote('(#{$filter-amount})');
  -o-filter: $filter-type+unquote('(#{$filter-amount})');
  filter: $filter-type+unquote('(#{$filter-amount})'); }
