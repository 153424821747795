.bgc {
    &--green {
        background-color: $green; }
    &--darkgreen {
        background-color: $darkgreen; }
    &--black {
        background-color: $black; }
    &--dimgray {
        background-color: $dimgray; }
    &--gray {
        background-color: $gray; }
    &--silver {
        background-color: $silver; }
    &--white {
        background-color: $white; }
    &--lightgreen {
        background-color: $lightgreen; }
    &--gainsboro {
        background-color: $gainsboro; } }

// Colors
.cl {
	&--black {
		color: $black;
		@include heading {
            color: $black; } } }

.cl {
    &--white {
        color: $white;
        @include heading {
            color: $white; } } }


// lines
.line {
    &_bottom {
        &--green {
            border-bottom: 5px solid $green; }
        &--black {
            border-bottom: 5px solid $black; } }
    &_top {
        &--green {
            border-top: 5px solid $green; }
        &--black {
            border-top: 5px solid $black; } } }
// Text
.text {
	&_cl {
		&--green {
			color: $green; }
		&--white {
			color: $white; }
		&--black {
			color: $black; } } }

// Font Weight
.fw {
    &--300 {
        font-weight: 300; }
    &--400 {
        font-weight: 400; }
    &--500 {
        font-weight: 500; }
    &--600 {
        font-weight: 600; }
    &--700 {
        font-weight: 700; } }

.display {
    &-inline {
        display: inline; } }

// Margins

.mg {
    &_box {
        &--x {
            &-5 {
                margin: 5px; }
            &-10 {
                margin: 10px; }
            &-20 {
            	margin: 20px; } } }
    &_top {
        &--x {
            &5 {
                margin-top: 5px; }
            &10 {
                margin-top: 10px; }
            &20 {
                margin-top: 20px; }
            &40 {
                margin-top: 40px; }
            &70 {
                margin-top: 70px; }
            &100 {
                margin-top: 100px; }
            &130 {
                margin-top: 130px; }
            &150 {
                margin-top: 150px; } } }
    &_bottom {
        &--x {
            &10 {
                margin-bottom: 10px; }
            &20 {
                margin-bottom: 20px; }
            &40 {
                margin-bottom: 40px; }
            &70 {
                margin-bottom: 70px; }
            &100 {
                margin-bottom: 100px; }
            &150 {
                margin-bottom: 150px; } } } }

// Font Size

.font {
    &--10 {
        font-size: 10px; }
    &--12 {
        font-size: 12px; }
    &--14 {
        font-size: 14px; }
    &--16 {
        font-size: 16px; }
    &--18 {
        font-size: 18px; }
    &--20 {
        font-size: 20px; }
    &--30 {
        font-size: 30px; }
    &--35 {
        font-size: 35px; } }

.uppercase {
    text-transform: uppercase; }

.float-right {
    float: right; }

.float-left {
    float: left; }

.line {
    &-bottom {
        &-gray {
            border-bottom: 1px solid $gray;
            &-detail {
                margin-top: 35px; }
            &-history {
                margin-top: 50px; } } } }
