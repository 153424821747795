.box-line {
    border: 1px solid $gray; }

.number-guide {
    padding: 5px; }

.list {
    &-active>li>a {
        position: relative;
        display: block;
        padding: 10px 30px 10px;
        text-decoration: none !important;
        background-color: $gainsboro; } }


.nav-detail {
    border-bottom: transparent; }
.list {
    &-active>li>a {
        color: $gray; } }

.nav-detail>li.active>a, .nav-detail>li.active>a:focus, .nav-detail>li.active>a:hover {
    color: $green;
    background-color: $white;
    border: 1px solid $gray; }

.nav-detail>li>a:hover {
    border-color: transparent; }

.nav>li>a:focus, .nav>li>a:hover {
    background-color: transparent; }

.nav-detail>li>a:hover {
    border-color: transparent; }

.img-main-news {
    img {
        border-radius: 18px 18px 18px 18px; } }
