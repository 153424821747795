.form {
    &-control {
        &--green {
            background-color: transparent;
            border-top: 0px solid transparent;
            border-right: 2px solid $green;
            border-bottom: 2px solid $green;
            border-left: 2px solid $green;
            border-radius: 0px;
            box-shadow: inherit;
            color: $green;
            @include placeholder {
                color: $green; }
            &:focus {
                box-shadow: inset 0 1px 1px rgba($white,- 3.925), 0 0 0px rgba($green,.9);
                border-color: transparent;
                border-top: 0px solid transparent;
                border-right: 2px solid $darkgreen;
                border-bottom: 2px solid $darkgreen;
                border-left: 2px solid $darkgreen; } }
        &--white {
            background-color: transparent;
            border-top: 0px solid transparent;
            border-right: 2px solid $white;
            border-bottom: 2px solid $white;
            border-left: 2px solid $white;
            border-radius: 0px;
            box-shadow: inherit;
            color: $white;
            @include placeholder {
                color: $white; }
            &:focus {
                box-shadow: inset 0 1px 1px rgba($white,- 3.925), 0 0 0px rgba($gray,.9);
                border-color: transparent;
                border-top: 0px solid transparent;
                border-right: 2px solid $gray;
                border-bottom: 2px solid $gray;
                border-left: 2px solid $gray; } } } }

#msform {
    width: 100%;
    margin: 50px auto;
    text-align: center;
    position: relative; }

#msform fieldset {
    background: white;
    border-radius: 3px;
    padding: 20px 30px;
    box-sizing: border-box;
    width: 80%;
    margin: 0 10%;
    position: relative;
    margin-bottom: 40px; }

#msform fieldset:not(:first-of-type) {
    display: none; }

#msform .action-button {
    width: 100px;
    background: $green;
    color: white;
    border: 0 none;
    border-radius: 5px;
    cursor: pointer;
    padding: 10px 5px;
    margin: 10px 5px;
    outline: none; }

.fs-title {
    font-size: 15px;
    text-transform: uppercase;
    margin-bottom: 10px; }

.fs-subtitle {
    font-weight: normal;
    font-size: 13px;
    color: #666;
    margin-bottom: 20px; }

#progressbar {
    margin-bottom: 30px;
    overflow: hidden;
    counter-reset: step; }

#progressbar li {
    list-style-type: none;
    text-transform: uppercase;
    font-size: 14px;
    width: 33.33%;
    float: left;
    position: relative; }

#progressbar li:before {
    content: counter(step);
    counter-increment: step;
    width: 40px;
    line-height: 40px;
    display: block;
    font-size: 10px;
    color: #333;
    border-radius: 40px;
    margin: 0 auto 5px auto;
    background-color: $gainsboro; }

#progressbar li:after {
    content: '';
    width: 100%;
    height: 2px;
    position: absolute;
    left: -50%;
    top: 19px;
    z-index: -1;
    background-color: $gainsboro; }

#progressbar li:first-child:after {
    content: none; }

#progressbar li.active:before,  #progressbar li.active:after {
    background-color: $green;
    color: $white; }

input.next.action-button:focus {
    outline: none; }

input.previous.action-button:focus {
    outline: none; }

.contour {
    border: 1px solid $gainsboro; }

.guide {
    background: $gainsboro;
    margin-top: 30px;
    padding: 1px;
    border-bottom: 1px solid $gray; }

.containt-text {
    border-bottom: 1px solid $gray;
    padding: 1px; }


